import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertsServiceApi } from '@cohesity/api/alert-server';
import { IncidenceList, ShieldType, ShieldsApiService } from '@cohesity/api/argus';
import { HeliosRulesApiService, RuleType } from '@cohesity/api/helios-metadata';
import { Api, McmClusterServiceApi } from '@cohesity/api/private';
import { CopyStatsServiceApi, ObjectServiceApi, SecurityServiceApi } from '@cohesity/api/v2';
import { HmsTaggingWrapperService } from '@cohesity/data-govern/shared';
import { IrisContextService, flagEnabled, getMcmAccountId, getUserTenantId, isRpaasScope } from '@cohesity/iris-core';
import { regionCoordinates } from '@cohesity/iris-shared-constants';
import { TranslateService } from '@ngx-translate/core';
import { PointMarkerOptionsObject, PointOptionsObject } from 'highcharts';
import { chain, cloneDeep, groupBy, merge, reduce, uniq } from 'lodash';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  AlertState,
  AnomalyAlert,
  AnomalyChartSeries,
  AnomalyStats,
  ClusterLocation,
  DataPoint,
  MetricSeries,
  MetricType,
  RecoverySource,
  SnapshotStatus,
  TimeRange,
} from './../security-shared.models';
import { addAllMetrics, pruneUnsupportedMetrics } from './utils';

/**
 * This service is for displaying data in security dashboard.
 * It maintains recovery cart and other service calls will be added as per availability.
 */
@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  /**
   * Anomaly stats states
   */
  private _anomalyStatsSubject = new BehaviorSubject<AnomalyStats>(null);

  /**
   * An observable with anomaly stats.
   */
  anomalyStats$ = this._anomalyStatsSubject.asObservable();

  /**
   * Location of clusters.
   */
  private _clusterLocSubject = new BehaviorSubject<ClusterLocation[]>(null);

  /**
   * An observable with location of clusters.
   */
  clusterLoc$ = this._clusterLocSubject.asObservable();

  /**
   * Indicate whether data is being fetched.
   */
  private _isSummaryLoading$ = new BehaviorSubject<boolean>(null);

  /**
   * An observable for indicating whether data is being fetched.
   */
  isSummaryLoading$ = this._isSummaryLoading$.asObservable();

  /**
   * Snapshot tagging configuration
   */
  get snapshotTaggingConfig() {
    const { irisContext } = this.irisCtx;
    return {
      tagManagement: irisContext.privs.SNAPSHOT_TAGS_MANAGE,
      restoreManagement: irisContext.privs.SNAPSHOT_TAGS_RESTORE,
    };
  }

  /**
   * flag to check if clean room recovery is enabled
   */
  get isCleanRoomRecoveryPhase1Enabled() {
    return flagEnabled(this.irisCtx.irisContext, 'cleanRoomRecoveryPhase1');
  }

  /**
   * Current action threshold value
   */
  readonly anomalyStrengthThreshold$: Observable<number> = this.heliosSecurityService
    .GetAnomalyAlertNotifLevel()
    .pipe(
      map(response => response?.notificationInfo?.anomalyStrengthThreshold || 0),
    );

  /**
   * Cluster locations
   */
  private readonly clusterLocations$ = this.mcmClusterServiceApi.getClusterLocations().pipe(shareReplay(1));

  /**
   * Uses new API to update alerts status and ignores old alerts API when listing anomalies
   */
  private readonly useNewEventIndex = flagEnabled(this.irisCtx.irisContext, 'ransomwareAlertsApiUpdate');

  constructor(
    private alertService: AlertsServiceApi,
    private copyStatsServiceApi: CopyStatsServiceApi,
    private heliosRulesApiService: HeliosRulesApiService,
    private heliosSecurityService: SecurityServiceApi,
    private hmsWrapperService: HmsTaggingWrapperService,
    private http: HttpClient,
    private irisCtx: IrisContextService,
    private log: NGXLogger,
    private mcmClusterServiceApi: McmClusterServiceApi,
    private objectServiceApi: ObjectServiceApi,
    private shieldsApiService: ShieldsApiService,
    private translateService: TranslateService
  ) {}

  /**
   * Subject to handle time frame changes across tabs
   */
  private timeFrame$ = new BehaviorSubject<TimeRange>({});

  /**
   * Returns current time frame
   */
  getTimeFrame() {
    return this.timeFrame$.asObservable();
  }

  /**
   * Sets time frame
   *
   * @param   timeRange   startTime and endTime
   */
  setTimeFrame(timeRange: TimeRange) {
    this.timeFrame$.next(timeRange);
  }

  /**
   * Gets the summary of the anomaly summary info to be rendered.
   *
   * @param   timeRange  The time range.
   */
  getAnomalySummaryInfo(timeRange: TimeRange) {
    if (!timeRange?.startTime || !timeRange?.endTime) {
      this.log.fatal('Time Range can not be empty for querying ransomware alerts');
      return;
    }

    this._isSummaryLoading$.next(true);

    const incidences$: Observable<IncidenceList> =
      this.shieldsApiService.getIncidences({
        shieldTypes: [ShieldType.ANTI_RANSOMWARE],
        startTimeMsecs: timeRange.startTime / 1000,
        endTimeMsecs: timeRange.endTime / 1000,
      }).pipe(map(res => res?.incidences));

    const alerts$: Observable<AnomalyAlert[]> = this.useNewEventIndex ?
      of(null) : this.getAnomalies(timeRange);

    // There is no private YAML definition for this.
    return forkJoin([
      this.clusterLocations$,
      this.getAggregatedStats(timeRange),
      incidences$,
      alerts$
    ]).pipe(
      finalize(() => this._isSummaryLoading$.next(false)),
      map(([clusterLocations, stats, incidences, alerts]) => {
        const ret: [ClusterLocation[]?, AnomalyStats?, IncidenceList?, AnomalyAlert[]?] = [];

        const anomalies = this.transformAnomalies(alerts);
        let clustersById;
        if (incidences?.length) {
          clustersById = groupBy(incidences.map(incidence => ({
            clusterId: incidence?.antiRansomwareDetails?.clusterId,
            clusterName: incidence?.antiRansomwareDetails?.clusterName
          })), 'clusterId');
        } else if (anomalies?.length) {
          clustersById = groupBy(anomalies.map(alert => ({
            clusterId: alert?.clusterId,
            clusterName: alert?.clusterName
          })), 'clusterId');
        }

        const parsedLocation = clusterLocations?.map((location): ClusterLocation => {
          const clusters = clustersById?.[location.clusterId];

          if (!clusters?.length) {
            return;
          }

          return {
            clusterId: location.clusterId,
            clusterName: clusters[0]?.clusterName,
            lat: location.lat,
            lon: location.lon,
            z: clusters.length,
          };
        }).filter(Boolean);

        if (incidences?.length) {
          const incidencesByRegion = groupBy(incidences, incidence => incidence?.antiRansomwareDetails?.baasRegionId);
          const baasRegions = uniq(incidences
            .filter(incidence => incidence?.antiRansomwareDetails?.isBaas)
            .map(incidence => incidence.antiRansomwareDetails?.baasRegionId)
          ).map(region => ({
            ...regionCoordinates[region],
            z: incidencesByRegion[region].length,
            clusterName: this.translateService.instant(region),
          }));

          if (baasRegions?.length > 0) {
            parsedLocation.push(...baasRegions);
          }
        }

        ret.push(parsedLocation, stats, incidences, anomalies);

        return ret;
      }),
      tap(([locations, stats, _incidences]) => {
        this._clusterLocSubject.next(locations);
        this._anomalyStatsSubject.next(stats);
      })
    );
  }

  /**
   * Gets list of all anomalies.
   * This is only called in 'All Cluster' scope.
   *
   * @param timeRange   startDate and endDate
   */
  getAnomalies(timeRange: TimeRange) {
    const params: any = {
      alertCategoryList: ['kSecurity'],
      alertStateList: ['kOpen'],
      endDateUsecs: timeRange.endTime.toString(),
      maxAlerts: '1000',
      startDateUsecs: timeRange.startTime.toString(),
      _includeTenantInfo: 'true',
    };

    const url = Api.mcm('alerts');
    return this.http.get<AnomalyAlert[]>(url, { params });
  }

  /**
   * Gets anomaly details.
   *
   * @param id    Anomaly id
   */
  getAnomaly(id: string) {
    const url = Api.mcm('alerts', id);
    return this.http.get<AnomalyAlert>(url);
  }

  /**
   * Gets the statistics and information about an alert that was raised.
   *
   * @param   alert   The corresponding alert
   * @param   snapshotType  Whether to use local or cloud data vaults.
   * @returns AN observable with series and objects.
   */
  getAnomalyAlertStats(alert: AnomalyAlert, snapshotType = RecoverySource.local): Observable<AnomalyChartSeries> {
    const clusterId = alert.properties.cid || alert.clusterId;
    const clusterIdentifier = `${clusterId}:${alert.properties.clusterIncarnationId}`;

    // Retrieve snapshot details between the alert generated time.
    const timeRange = moment(alert.latestTimestampUsecs / 1000);
    const toUsecs = timeRange.add(1, 'days').valueOf() * 1000;
    const fromUsecs = timeRange.subtract(30, 'days').valueOf() * 1000;

    const tenantIds = alert.incidence?.antiRansomwareDetails?.isBaas ?
      [getUserTenantId(this.irisCtx.irisContext)].filter(Boolean) : [];

    return forkJoin([
      this.copyStatsServiceApi.GetCopyStats({
        clusterIdentifiers: [clusterIdentifier],
        tenantIds,
        objectIds: [Number(alert.properties.entityId)],
        protectionGroupIds: [`${clusterIdentifier}:${alert.properties.jobId}`],
        fromRunStartTimeUsecs: fromUsecs,
        toRunStartTimeUsecs: toUsecs,
        pageSize: 1_000,
      }),
      this.getAnomalyStats(alert),
    ]).pipe(
      map(([value, stats]): AnomalyChartSeries => {
        let rpaasData = value || [];
        if (isRpaasScope(this.irisCtx.irisContext)) {
          rpaasData = value.filter(res => res.archivals.find(arch => arch?.isRPaas));
        }

        const dataPointVec = rpaasData.map((data): DataPoint => {
          const { storageMetrics, local, indexingStats, taggingInfo, anomalyStatus, archivals } = data;

          // Determine whether the snapshot is expired or not based on the type.
          let snapshotExpired =
            local?.status === SnapshotStatus.StatusDeleted || local?.status === SnapshotStatus.StatusExpired;
          if (snapshotType === RecoverySource.vault) {
            const archival = (archivals || []).find(arch =>
              isRpaasScope(this.irisCtx.irisContext) ? arch.isRPaas : !arch.isRPaas
            );

            snapshotExpired =
              archival?.status === SnapshotStatus.StatusDeleted || archival?.status === SnapshotStatus.StatusExpired;
          }

          return {
            // The collected metrics.
            bytesWritten: storageMetrics?.dataWrittenBytes,
            compressionRatio: +storageMetrics?.compressionRatio?.toFixed(2),
            deduplicationRatio: +storageMetrics?.compressionRatio?.toFixed(2),
            numFilesAdded: indexingStats?.newDocumentCount,
            numFilesChanged: indexingStats?.updatedDocumentCount,
            numFilesDeleted: indexingStats?.deletedDocumentCount,
            numFilesUnchanged: indexingStats?.notUpdatedDocumentCount,

            // The AntiRansomware tag asssocated used to prevent recovery from a malicious snapshot.
            tags: taggingInfo?.tags,

            // The meta data about the snapshot.
            jobInstanceId: local?.runInstanceId,
            timestampUsecs: local?.runStartTimeUsecs,
            isAnomalousSnapshot: Boolean(anomalyStatus?.isAnomaly),
            snapshotInfo: {
              localSnapshot: local?.snapshotId,
              archivalSnapshot: archivals,
              snapshotExpired,
            },
          };
        });

        const metrics = stats.metrics;

        return {
          metrics,
          dataPointVec,
          chartMetric: stats.chartMetric,
        };
      }),
      switchMap(seriesData =>
        this.isCleanRoomRecoveryPhase1Enabled
          ? this.populateSnapshotTagInformation(seriesData, snapshotType)
          : of(seriesData)
      ),
      map(seriesData => this.transformAnomalyStats(seriesData, alert))
    );
  }

  /**
   * Enriches the given series with the associated tag information
   *
   * @param seriesData anomaly chart series for which the tag information need to be added
   * @param   snapshotType  Whether to use local or cloud data vaults.
   * @returns observable for updated anomaly chart series containing the tagged snapshot information
   */
  populateSnapshotTagInformation(
    seriesData: AnomalyChartSeries,
    snapshotType = RecoverySource.local,
  ): Observable<AnomalyChartSeries> {
    const snapshotIdMap = seriesData.dataPointVec?.reduce((acc, dataPoint) => {
      const timestamp = dataPoint.timestampUsecs;
      const snapshotId = this.getDataPointSnapshotId(dataPoint, snapshotType);
      if (snapshotId) {
        acc[timestamp] = snapshotId;
      }
      return acc;
    }, {} as Record<number, string>);

    return forkJoin([
      this.heliosRulesApiService.executeRuleOp({
        body: {
          ruleType: RuleType.BlockSnapshotRecovery,
          snapshotIds: Object.values(snapshotIdMap),
        }
      }),
      this.hmsWrapperService.listAssociatedTags({
        snapshotIds: Object.values(snapshotIdMap),
      }),
    ]).pipe(
      map(([recoverabilityStatus, tagInformation]) => {
        const transformedData = seriesData;

        transformedData.dataPointVec = transformedData.dataPointVec?.map((dataPoint) => {
          const snapshotId = this.getDataPointSnapshotId(dataPoint, snapshotType);
          if (snapshotId) {
            // populate recoverability status for the data points
            const snapshotRecoverabilityStatus = recoverabilityStatus.result
              ?.find((resp) => resp.snapshotId === snapshotId);
            dataPoint.isRecoverable = !snapshotRecoverabilityStatus.shouldBlockRecovery;

            // populate the tag list
            const snapshotTagInfo = tagInformation.snapshotTags?.find((entry) => entry.snapshotId === snapshotId);
            dataPoint.hmsTags = snapshotTagInfo.tags;
          }
          return dataPoint;
        });

        return transformedData;
      }),
      catchError(() => of(seriesData)),
    );
  }

  /**
   * Extracts the snapshot id from the given data point based on the supplied snapshot type
   *
   * @param dataPoint data from which the snapshot id need to be extracted
   * @param snapshotType currently selected snapshot type
   * @returns snapshot id
   */
  getDataPointSnapshotId(dataPoint: DataPoint, snapshotType = RecoverySource.local) {
    if (snapshotType === RecoverySource.vault && dataPoint.snapshotInfo.archivalSnapshot?.length) {
      return dataPoint.snapshotInfo.archivalSnapshot[0].snapshotId;
    } else if (snapshotType === RecoverySource.local) {
      return dataPoint.snapshotInfo?.localSnapshot;
    }

    return null;
  }

  /**
   * Transform anomaly property list and filter by supported entities
   *
   * @param anomalies   All anomaly alerts
   * @returns Filtered alerts according to supported entities
   */
  transformAnomalies(anomalies: AnomalyAlert[]) {
    const { irisContext } = this.irisCtx;
    return anomalies?.filter((anomaly: AnomalyAlert) => {
      // Check alert code. Anomaly alerts have a code of 'CE01516011'
      const isValid = anomaly.alertCode === 'CE01516011';

      if (!isValid) {
        return false;
      }

      anomaly.properties = reduce(
        anomaly.propertyList,
        (result, item) => {
          result[item.key] = item.value;
          return result;
        },
        {}
      );

      // accountId is not attached to tenantId on anomaly list
      // in tenant-column component tenant details processing with below format
      anomaly.tenantIds = (anomaly.tenantIds || []).map(tenantId => `${getMcmAccountId(irisContext)}:${tenantId}`);

      return true;
    });
  }

  /**
   * Updates anomaly's state.
   */
  updateAnomalyState(anomaly: AnomalyAlert, state: AlertState) {
    if (this.useNewEventIndex && anomaly.incidence?.id) {
      return this.updateAlertState(anomaly.incidence.id, state);
    } else {
      return this.updateAnomalyStatus(anomaly.id, state);
    }
  }

  /**
   * Updates alert state based on incidence id.
   *
   * @param incidenceId Incidence ID
   * @param state Alert state
   */
  updateAlertState(incidenceId: string, state: AlertState) {
    return this.alertService.UpdateAlertState({
      id: incidenceId,
      body: {
        alert_state: state,
      }
    });
  }

  /**
   * Suppress anomaly.
   *
   * @param id    Anomaly id
   * @param status  kSuppressed / kResolved
   */
  updateAnomalyStatus(id: string, status: AlertState) {
    const url = Api.mcm('alerts', id);
    return this.http.patch<any>(url, { status: `k${status}` });
  }

  /**
   * Gets anomaly stats for line graph.
   *
   * @param anomaly   Anomaly alert
   */
  getAnomalyStats(anomaly: AnomalyAlert) {
    const url = Api.mcm('alerts', anomaly.id, 'statistics');
    return this.http.get<AnomalyChartSeries>(url).pipe(
      map(seriesData => addAllMetrics(seriesData)),
      map(response => pruneUnsupportedMetrics(response)),
    );
  }

  /**
   * Transforms anomaly stats for chart.
   */
  transformAnomalyStats(response: AnomalyChartSeries, anomaly: AnomalyAlert): AnomalyChartSeries {
    // group by time for quick lookup
    response.timestampDataPoints = groupBy(response.dataPointVec, 'timestampUsecs');

    // disable marker settings
    const disableMarker: object | PointMarkerOptionsObject = {
      marker: {
        enabled: false,
      },

      // Custom property to disable tooltip
      noTooltip: true,
    };

    response.anomalousDataPoints = [];
    response.expiredDataPoints = [];

    // Create base version of transformed data points and then update Y values as per chart metric
    response.transformedDataPoints = chain(response.dataPointVec)
      .sortBy('timestampUsecs')
      .map(dataPoint => {
        const chartPoint: PointOptionsObject | PointOptionsObject = {
          x: dataPoint.timestampUsecs / 1000,
          y: dataPoint[MetricType[response.chartMetric]],
          z: 0.125,
        };

        const isDataPointNonRecoverable = this.isCleanRoomRecoveryPhase1Enabled && dataPoint.isRecoverable === false;
        let latestCleanSnapshotMarked = false;

        /**
         * create anomalous & clean data points array
         */
        switch (true) {
          case anomaly.dedupTimestamps.includes(dataPoint.timestampUsecs) || isDataPointNonRecoverable:
            response.timestampDataPoints[dataPoint.timestampUsecs][0].tooltip =
              this.translateService.instant('anomalousSnapshot');
            response.anomalousDataPoints.push(cloneDeep(chartPoint));
            merge(chartPoint, disableMarker);
            break;

          case dataPoint.timestampUsecs === Number(anomaly.properties.jobStartTimeUsecs):
            response.timestampDataPoints[dataPoint.timestampUsecs][0].tooltip =
              this.translateService.instant('latestCleanSnapshot');
            response.latestCleanDataPoint = cloneDeep(chartPoint);
            merge(chartPoint, disableMarker);
            latestCleanSnapshotMarked = true;
            break;

          default:
            // if recovery rules are enabled, latest clean snapshot returned by API
            // could be marked anomalous because of a matched tag. In that case, use
            // the first clean snapshot as the lastest clean snapshot
            if (this.isCleanRoomRecoveryPhase1Enabled && !latestCleanSnapshotMarked) {
              response.timestampDataPoints[dataPoint.timestampUsecs][0].tooltip =
                this.translateService.instant('latestCleanSnapshot');
              response.latestCleanDataPoint = cloneDeep(chartPoint);
              merge(chartPoint, disableMarker);
              latestCleanSnapshotMarked = true;
            } else {
              response.timestampDataPoints[dataPoint.timestampUsecs][0].tooltip =
                this.translateService.instant('cleanSnapshot');
            }

            break;
        }

        if (dataPoint?.snapshotInfo?.snapshotExpired) {
          response.expiredDataPoints.push(cloneDeep(chartPoint));
          response.timestampDataPoints[dataPoint.timestampUsecs][0].tooltip =
            this.translateService.instant('expiredSnapshot');
          merge(chartPoint, disableMarker);
        }

        return chartPoint;
      })
      .value();

    // if snapshot information is available, generate the tagged data points
    if (this.isCleanRoomRecoveryPhase1Enabled) {
      // get the max y of the transformed data points to compute the threshold for showing the tagged marker
      const maxY = Math.max(...response.transformedDataPoints.map(p => p.y || 0));

      response.taggedDataPoints = response.transformedDataPoints?.map((chartPoint) => {
        const dataPoint = response.timestampDataPoints[chartPoint.x * 1000][0];
        if (!dataPoint.hmsTags || dataPoint.hmsTags?.length === 0) {
          return null;
        }

        return {
          ...chartPoint,
          // compute the y position, rest is same as existing transformed data point
          y: maxY * 0.15 + chartPoint.y,
          noTooltip: false,
          marker: null,
          tooltip: [
            this.translateService.instant('tags'),
            '<br/>',
            dataPoint.hmsTags.map((tag) => tag.name).join(', '),
          ],
        };
      }).filter(Boolean);
    }

    return response;
  }

  /**
   * Transform anomaly and series data to metric series
   *
   * @param data anomaly details
   * @param seriesData anomaly series data
   * @returns metric series
   */
  getMetricSeries(data: AnomalyAlert, seriesData: AnomalyChartSeries): MetricSeries {
    const metricSeries: MetricSeries = {};
    seriesData?.metrics.forEach(metric => {
      seriesData.chartMetric = metric;
      metricSeries[metric] = cloneDeep(this.transformAnomalyStats(seriesData, data));
    });
    return metricSeries;
  }

  /**
   * Get Aggregated stats for security dashboard
   *
   * @param timeRange   startDate and endDate
   */
  getAggregatedStats(timeRange: TimeRange) {
    let httpParams = new HttpParams()
      .set('startDateUsecs', timeRange.startTime.toString())
      .set('endDateUsecs', timeRange.endTime.toString());

    if (isRpaasScope(this.irisCtx.irisContext)) {
      httpParams = httpParams.set('rpaasOnly', 'true');
    }

    const url = Api.mcm('security/anomalies/stats');
    return this.http.get<any>(url, { params: httpParams });
  }

  /**
   * Gets the snapshotId of the object to be recovered.
   * Need to use toTime and fromTime since API doesn't support filtering based on runStartTime
   * So we take runStartTime and create 2 mins gap to filter snapshot id.
   *
   * @param   objectId  Selected object unique identifier
   * @param   snapshotTimestampUsec   Object snapshot timestamp
   * @returns   Observable returning snapshot Id
   */
  getSnapshotId(objectId: number, snapshotTimestampUsec: number, regionId: string) {
    const params: ObjectServiceApi.GetObjectSnapshotsParams = {
      id: objectId,
      runTypes: ['kRegular'],
      // Reduce 2 mins
      fromTimeUsecs: snapshotTimestampUsec - Math.pow(10, 8),
      toTimeUsecs: snapshotTimestampUsec,
      regionId,
    };

    return this.objectServiceApi.GetObjectSnapshots(params).pipe(
      filter(response => response.snapshots.length > 0),
      map(response => response.snapshots[0].id)
    );
  }
}
